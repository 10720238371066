// RHT

export default {
  settings: {
    disableIframePostMessage: false,
    ctaWhiteBg: "true",
  },
  content: {
    general: {
      clientName: "RHT Contracting",
    },
    superFund: `
      <div class="section-title mt-5">
        <h2>
          Colonial First State – FirstChoice Employer Super
        </h2>
      </div>
      <div class="row content">
        <div class="col-lg-6">
          <p>
            By joining the company Superannuation fund, you benefit from group discount rates on fees and insurances. This means you pay much less than you would pay in a personal or individual plan.
          </p>
          <p>
            This website provides all the information you need around the RHT Contracting Superannuation Fund. There are helpful super tools and calculators, and information on other services we offer if you require additional support with your financial well-being.
          </p>
          <div class="col-lg-6 pt-4 pb-1 px-0 text-center text-lg-center">
            <a
              target="_blank"
              href="https://www.colonialfirststate.com.au/firstnet/login.aspx?CompanyCode=001"
              class="btn-learn-more"
            >
              FirstChoice Employer Super Member Login
            </a>
          </div>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p><b>Fees (per annum)</b></p>
          <ul>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Member Fee:
              <b>$60</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Administration Fee:
              <b>0.04%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Investment Fee:
              <b>0.57%-0.58%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Transaction costs:
              <b>0.02%- 0.05%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> TOTAL:
              <b>$60 + 0.63% - 0.67%</b>
            </li>
          </ul>
          <p>
            <b>Insurances</b>
          </p>
          <p>
            <b>Death & Total Permanent Disability Cover</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <b>Staff</b>: Age Based sum insured x 5 units at $1.48 per week per unit of cover
            </li>
          </ul>
          <p>
            <b>Salary Continuance insurance</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              this cover is available upon request and underwriting.
            </li>
          </ul>
          <p>
            <b>Investment Options</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Default Investment Option:</strong>
              FirstChoice Lifestage portfolios
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Investment returns available at:</strong>
              <a href="http://www3.colonialfirststate.com.au/" target="_blank">Colonial First State – FirstChoice Employer Super</a>
            </li>
          </ul>
        </div>
      </div>
      `,
    pdfs: [
      {
        title: "Building your Super Fact Sheet",
        description:
          "View all the different ways you can build your superannuation savings.",
        type: "form",
        url: "Gallagher-Building_Your_Super_Factsheet-2024-2025.pdf",
      },
      {
        title: "Join the RHT Contracting fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "form",
        url: "RHT_Contracting-New_Employee_Pack_October_2023.pdf",
      },
      {
        title: " Join the RHT Earthmoving Fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "form",
        url: "RHT_Earthmoving-New_Employee_Pack-October_23.pdf",
      },
      {
        title: "CFS Forms",
        description: "Find the form you need on the CFS website.",
        type: "form",
        externalUrl:
          "https://www.cfs.com.au/personal/resources/find-a-form/find-a-form.html#keyword=&productType=superannuation",
      },
      {
        title: "ATO Rollover Form",
        description:
          "Do you need to rollover your previous super fund to your new super fund?",
        type: "form",
        externalUrl:
          "https://www.ato.gov.au/Forms/Request-for-rollover-of-whole-balance-of-super-benefits-between-funds---Instructions/#Howtogettheform",
      },
      {
        title: "Product Disclosure Statement",
        description: "In-depth information on your super fund.",
        type: "info",
        externalUrl:
          "https://www.cfs.com.au/content/dam/prospects/fs/7/4/fs743.pdf?8",
      },
      {
        title: "Investment Options",
        description: "In depth information on your investment.",
        type: "info",
        externalUrl:
          "https://www.cfs.com.au/content/dam/prospects/fs/7/3/fs734.pdf?6",
      },
      {
        title: "Insurances",
        description: "In-depth information on your insurances.",
        type: "info",
        externalUrl:
          "https://www.cfs.com.au/content/dam/prospects/fs/1/9/fs1999.pdf?7",
      },
    ],
    teamMembers: [
      {
        teamMemberPic: "anthony.png",
        name: "Anthony Warman",
        phoneHref: "0862508308",
        phone: "08 6250 8308",
        email: "anthony_warman@ajg.com.au",
        social: "http://au.linkedin.com/pub/anthony-warman/7b/53a/75a",
      },
      {
        teamMemberPic: "phil.png",
        name: "Phil Mills",
        phoneHref: "0862508399",
        phone: "08 6250 8399",
        email: "phil_mills@ajg.com.au",
        social: "https://au.linkedin.com/pub/philip-mills/67/701/268",
      },
      {
        teamMemberPic: "natasha.png",
        name: "Natasha Van",
        phoneHref: "1300557782",
        phone: "1300 557 782",
        email: "super-service@ajg.com.au",
      },
    ],
    footerClientName: "RHT Contracting",
  },

  superscore: false,

  analytics: {
    gacode: "",
    gaprod: "",
  },
};
